import React, { useState, useMemo, useCallback } from "react"
import styled from "styled-components"

import {
    Box,
    Heading,
    Paragraph,
    Image,
    ResponsiveContext,
    Form,
    FormField,
    TextInput,
    Spinner
} from "grommet"

import StyledImageWrapper from "../components/styled_image_wrapper"
import StyledImage from "../components/styled_image"
import Seo from "../components/seo"
import Layout from "../components/layout"
import ImageWithOverlay from "../components/image_with_overlay"
import Button from "../components/linkbutton"
import { Succeed } from "../components/succeed"
import Container from "../components/container"

const CustomHeading = styled(Heading)`
    color: var(--blue);
    font-weight: bold;
`

const StyledHeader = styled(Heading)`
    position: relative;
`

const StyledIcon = styled(Image)`
    margin-left: -1.25em;
    height: 0.75em;
`

const StyledSpan = styled.span`
    color: #005DA8;
`

const StyledLink = styled.a`
    text-decoration: underline;
`

const HullPerformancePage = () => {

    const size = React.useContext(ResponsiveContext);

    const [formValue, setFormValue] = useState({ firstName: '', lastName: '',  emailAddress: '' });
    const [errorVisible, setErrorVisible] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const validateFields = useMemo(() => {
        const formValidation = { firstName: false, lastName: false,  emailAddress: false };

        let firstNameValid = formValue.firstName !== "" && formValue.firstName.length > 2;
        formValidation.firstName = firstNameValid;

        let lastNameValid = formValue.lastName !== "" && formValue.lastName.length > 2;
        formValidation.lastName = lastNameValid;

        let emailValid = formValue.emailAddress !== "" && formValue.emailAddress.indexOf("@") > -1 && formValue.emailAddress.indexOf(".") > -1;
        formValidation.emailAddress = emailValid;

        formValidation.all = firstNameValid && lastNameValid && emailValid;

        return formValidation;
    }, [formValue]);

    const onSubmitForm = useCallback(async () => {
        setErrorVisible(true);
        if (submitting || !validateFields.all) return;

        setSubmitting(true);

        const requestOptions = {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify(formValue),
            redirect: 'follow'
        };

        await fetch('https://api.ecomate.cloud/contact/downloadWhitepaper', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setFormValue({ firstName: '', lastName: '',  emailAddress: '' });
                    setErrorVisible(false);
                    alert("Thank you for your interest. The whitepaper will start downloading shortly.");

                    const pdfUrl = "/whitepaper-hull-performance-en.pdf";
                    const link = document.createElement("a");
                    link.href = pdfUrl;
                    link.download = "whitepaper.pdf";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            })
            .catch(() => alert("Something went wrong. Please try again later."));
        setSubmitting(false);
    }, [submitting, formValue, validateFields]);

    return (
        <Layout>
            <Seo title="Hull Performance" />
            <ImageWithOverlay backgroundSrc="/HP_Slider.png">
                <Container pad="large">
                    <StyledHeader>
                        <Box direction="row" gap="small" align="baseline">
                            <StyledIcon src="/favicon-32x32.png" />
                            <Heading color="white">
                                Navigate towards eco-efficiency:
                                <br />
                                EcoMATE™'s Hull Performance
                            </Heading>
                        </Box>
                    </StyledHeader>
                </Container>
            </ImageWithOverlay>
            <Container pad="large">
                <Box direction="row-responsive" gap="large">
                    <Box basis="3/4">
                        <CustomHeading level="2">
                            Introducing our latest commitment to efficiency: <br />
                            EcoMATE™ Hull Performance feature
                        </CustomHeading>
                        <Paragraph>
                            Hull cleaning is an important part of ship maintenance and has a significant impact on sustainability and maritime efficiency.
                            Monitoring hull performance with technologies such as EcoMATE allows you to directly optimize emission reduction efforts.
                        </Paragraph>
                        <Button style={{ width: "260px" }} to="/contact_us">
                            Get in touch with us today
                        </Button>
                    </Box>
                    <Box basis="1/2" justify="center">
                        <StyledImageWrapper pageSize={size}>
                            <StyledImage
                                fill
                                src="/hp_pictures.png"
                            />
                        </StyledImageWrapper>
                    </Box>
                </Box>
            </Container>
            <hr />
            <Container pad="large">
                <Box direction="row-responsive" gap="medium" align="center">
                    <Box basis="3/4">
                        <Paragraph>
                            <b>Hull performance is a critical factor in the maritime industry, directly impacting the efficiency, sustainability, and profitability of vessels.</b>
                            <br />
                            <br />
                            A well-optimized hull design reduces drag, minimizes fuel consumption, and lowers emissions, contributing to a cleaner environment and reduced operational costs.
                            If you're interested in delving deeper into the significance of hull performance, we invite you to download our white paper.
                            Inside, you'll find insights and discover how prioritizing hull performance can elevate your fleet's performance and drive sustainable success in the industry's current challenges.
                            <br />
                            <br />
                            <StyledSpan>Download the white paper below or check out our savings calculator. <StyledLink href="/savings_calculator">Here</StyledLink></StyledSpan>
                        </Paragraph>
                        <Box direction="column" gap="medium" pad="large" background="light-1" round="small">
                            <Form
                                value={formValue}
                                onChange={f => setFormValue(f)}
                                name="hpForm">
                                <Box direction="column" gap="small">
                                    <Box direction="row-responsive" gap="small">
                                        <FormField name="firstName" htmlFor="firstName" fill="horizontal" error={!validateFields.firstName && errorVisible ? "Fill out first name" : ""}>
                                            <TextInput placeholder="First Name" id="firstName" name="firstName" />
                                        </FormField>
                                        <FormField name="lastName" htmlFor="lastName" fill="horizontal" error={!validateFields.lastName && errorVisible ? "Fill out last name" : ""}>
                                            <TextInput placeholder="Last name" id="lastName" name="lastName" />
                                        </FormField>
                                    </Box>
                                    <FormField name="emailAddress" htmlFor="emailAddress" fill="horizontal" error={!validateFields.emailAddress && errorVisible ? "Fill out email" : ""}>
                                        <TextInput placeholder="Email" id="emailAddress" name="emailAddress" />
                                    </FormField>
                                    <Button type="button" style={{ alignSelf: "start" }} to="#hpForm" onClick={onSubmitForm}>
                                        {submitting ? <Spinner /> : "Download Whitepaper"}
                                    </Button>
                                </Box>
                            </Form>
                        </Box>
                    </Box>
                    <Box basis="1/2">
                        <Box direction="column" gap="medium" pad="large" background="#d8e5ef" align="center" round="small">
                            <Box basis="1/4">
                                <StyledImage
                                    src="/lightbulb.png" />
                            </Box>
                            <Paragraph textAlign="center">
                                Hull cleaning leads to an immediate fuel saving of around <b style={{ color: "#F15A29" }}>9%</b>.
                                For the largest commercial vessels this can result in saving of up to 14 tonnes of fuel per day valued at around $7000-14000.
                            </Paragraph>
                            <Paragraph textAlign="center" style={{ color: "#6E8191" }}>
                                (Adland et.al,. 2018)
                            </Paragraph>
                        </Box>
                    </Box>
                </Box>
            </Container>
            <Succeed>
                <Heading level="3" style={{ fontWeight: "bold" }}>
                    Fast return on investment
                </Heading>
                <Paragraph>
                    The amount of potential savings will vary depending on the
                    vessel and the extent of fuel-saving measures already
                    implemented. Benchmarking your ship will give crucial
                    information for fleet and vessel analysis after hull cleaning,
                    or prior to optimising actions. An instantaneous
                    overview of current fuel usage will also give the operator
                    information to adjust operations and maintain maximum
                    efficiency.
                </Paragraph>
                <Button style={{ width: "240px" }} to="/contact_us">
                    Get in touch with us today
                </Button>
            </Succeed>
        </Layout>
    )
}

export default HullPerformancePage
